// Here you can add other styles
.custom_card {
    margin-bottom: 20px;
    max-height: 500px;
    overflow-y: auto;
}
.add_new_button {
    width:100%;
    background-color: #000;
    color: #fff;
    & .icon {
        fill: #fff;
        margin-right: 10px;
    }
}
