input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
  }
}
.form-control:disabled {
  background-color: white;
}